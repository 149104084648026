







































































































































import {Component, Emit, Vue, Watch} from "vue-property-decorator";
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/lint/json-lint';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/comment-fold';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/indent-fold';
import 'codemirror/addon/fold/markdown-fold';
import 'codemirror/addon/fold/xml-fold';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/closetag';
import 'codemirror/theme/ayu-mirage.css';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/fold/foldgutter.css';

@Component({
    components:{
        codemirror,
    }
})

export default class EmailTemplate extends Vue{
    padding: boolean = false;
    search: string = '';
    sort: number = -1;
    page : number = 1;
    limitPage: number = 10;
    totalRow: number = 0

    selectTemplate = {};
    templateList: [] = [];
    options={
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: 'application/json',
        lineWrapping: true,
        theme: 'ayu-mirage',
        matchBrackets: true,
        autoCloseBrackets: true,
        autoCloseTags: true,
        //foldGutter: true,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
    }
    $refs!: Vue['$refs'] & {
        emailHtml:  codemirror ,
    }

    mounted() {
    }

    async created(){
        await this.emailTemplateList();
    }

    async emailTemplateList(){
        try{
            const sendData = {
                page: this.page,
                limitPage: this.limitPage,
                search: this.search,
                sort: this.sort,
            }
            this.padding = true;
            const { data } = await this.axios.get(`/etc/email/TemplateList`, {params: sendData});
            this.padding = false;
            const { result , list } = data;
            if(result === true){
                this.templateList = list;
            }
        }catch (e) {
            console.log(e);
        }
    }


    sortChange(){
        this.sort = this.sort * -1;
        this.page = 1;
        this.emailTemplateList();
    }

    closeTemplateModal(){
        this.$bvModal.hide('templateModal');
    }

    openTemplateModal(index: number){
        this.$bvModal.show('templateModal');
        this.selectTemplate = this.templateList[index];
        setTimeout(()=> {
            this.$refs.emailHtml.refresh()
        },300)
    }

    async saveTemplate(){
        try{
            const sendData = {
                ...this.selectTemplate
            }
            this.padding = true;
            const { data } = await this.axios.put(`/etc/email/saveTemplate`, sendData);
            this.padding = false;
            const { result } = data;
            //console.log(data, sendData);
            if(result === true) {
                this.$toast.success(`메일 폼이 저장되었습니다.`);
                this.closeTemplateModal();
            }

        }catch (e) {
            console.log(e);
        }
    }
}

